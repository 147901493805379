export default class HrdcData {
  constructor() {
    this.stagePriority = null;
    this.stageSlug = null;
    this.stageIndex = null;
    this.stageSlugToStageName = null;

    this.allStages = fetch(
      `${process.env.VUE_APP_SERVICE_URL_HRDC_TPDIY}/v1/en/console/stage/get-stages`
    )
      .then((res) => res.json())
      .then((result) => {
        // Process stage data and return.
        const allStage = [];
        const allStagePriority = [];
        const allStageSlug = [];
        const allStageIndex = [];
        const allStageSlugToStageName = [];
        for (let i = 0; i < result.data.length; i++) {
          let resultData = result.data[i];
          let stageName = resultData.name.toUpperCase().replace(/ /g, "_");
          let stageNameSlug = resultData.nameSlug;

          allStage[stageName] = resultData.id;
          allStagePriority[stageNameSlug] = resultData.id;
          allStageSlug[stageNameSlug] = resultData.stagePriority;
          allStageIndex[resultData.id] = resultData.stagePriority;
          allStageSlugToStageName[stageNameSlug] = resultData.name;
        }

        this.stagePriority = allStagePriority;
        this.stageSlug = allStageSlug;
        this.stageIndex = allStageIndex;
        this.stageSlugToStageName = allStageSlugToStageName;
        return allStage;
      });
  }
}
